<template>
  <div class="home">
    <div class="all">
      <div class="name">{{ detail.name }}</div>
      <div class="content">
        <div class="left">
          <div class="location">{{ detail.location }}</div>
          <div class="title">个人简介</div>
          <div class="intro">{{ detail.selfIntroduction }}</div>
          <div class="title title2">代表论著</div>
        </div>
        <img :src="detail.picture" alt="">
      </div>

      <div class="duction" v-html="detail.personageIntroduction"></div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  name: '',
  components: {

  },
  // 定义属性
  data() {
    return {
      detail: {}
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    init() { }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if (this.$route.query.detail) {
      let formObj = decodeURIComponent(this.$route.query.detail)
      this.detail = JSON.parse(formObj)
      console.log('参数', this.detail.personageIntroduction);
    
    }

    this.init()
  },
}
</script>

<style scoped>
.home {
  background: #EDF4F8;
  box-sizing: border-box;
  padding-top: 25px;
  padding-bottom: 80px;
  overflow-y: auto;
}

.all {
  width: 73%;
  margin: 0 auto;
  background: #FFFFFF;
  padding: 30px;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);

  .name {
    font-weight: 600;
    font-size: 30px;
    color: #333333;
  }

  .title {
    margin-top: 20px;
    display: inline-block;
    font-weight: 600;
    font-size: 20px;
    color: #4FA0F8;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      background: #4FA0F8;
      bottom: -10px;
      left: 0;
    }
  }

  .content {
    margin-top: 20px;
    display: flex;

    .left {
      flex: 1;
    position: relative;
      .location {
        font-weight: 400;
        font-size: 18px;
        color: #333333;
      }

      .intro {
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        margin-top: 20px;
      }

      .title2 {
        position: absolute;
        bottom: 0;
      }
    }

    img {
      width: 214px;
      height: 282px;
      object-fit: cover;
    }
  }

  .duction {
    margin-top: 20px;
    width: 100%;
  }
}
</style>